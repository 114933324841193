import { ActionIcon, Button, Group, SimpleGrid, Stack, useMantineTheme } from '@mantine/core';
import { IconPencil, IconPoint, IconTrashX } from '@tabler/icons-react';
import P1Medium from 'components/typography/P1Medium';
import P1Regular from 'components/typography/P1Regular';
import P2Regular from 'components/typography/P2Regular';
import P4Regular from 'components/typography/P4Regular';

export type EvidenceField = {
  key?: string;
  evidenceFieldId?: number;
  fieldName: string;
  fieldType: 'number' | 'date' | 'text';
  isRequired: boolean;
  deleted?: boolean;
  precision?: number;
};

interface EvidenceFieldRowProps {
  field: EvidenceField;
  onEdit: () => void;
  onDelete: () => void;
}

/**
 * Translates the type of the evidence field to a human-readable form.
 */
function translateType(type: 'number' | 'date' | 'text') {
  switch (type) {
    case 'number':
      return 'Číslo';
    case 'date':
      return 'Dátum';
    case 'text':
      return 'Text';
  }
}

/**
 * Renders the required label.
 */
function RequiredPropLabel({ required }: { required: boolean }) {
  const theme = useMantineTheme();

  return (
    <Group>
      <Group position="center" style={{ borderRadius: '50%' }} bg={required ? 'red.1' : 'gray.1'} h={24} w={24}>
        <IconPoint
          fill={required ? theme.colors.red[8] : theme.colors.gray[6]}
          color={required ? theme.colors.red[8] : theme.colors.gray[6]}
          size={16}
        />
      </Group>
      <P2Regular color={required ? 'red.8' : 'gray.6'}>{required ? 'Povinné' : 'Nepovinné'}</P2Regular>
    </Group>
  );
}

/**
 * Single row in the evidence fields list.
 */
export default function EvidenceFieldRow({ field, onEdit, onDelete }: EvidenceFieldRowProps) {
  const theme = useMantineTheme();

  return (
    <SimpleGrid
      cols={4}
      style={{ borderBottom: `1px solid ${theme.colors.gray[1]}`, alignItems: 'flex-start' }}
      px={16}
      py={24}
    >
      <P1Medium color="gray.8">{field.fieldName}</P1Medium>
      <Stack spacing={4}>
        <P1Regular color="gray.8">{translateType(field.fieldType)}</P1Regular>
        {field.fieldType === 'number' && (
          <P4Regular color="gray.6">Počet desatinných miest: {field.precision || 0}</P4Regular>
        )}
      </Stack>
      <RequiredPropLabel required={field.isRequired} />
      <Group position="right" spacing={12}>
        <Button
          variant="secondary"
          leftIcon={<IconPencil stroke={1.5} color={theme.colors.blue[9]} />}
          onClick={onEdit}
        >
          Editovať
        </Button>
        <ActionIcon variant="danger-secondary" onClick={onDelete}>
          <IconTrashX stroke={1.5} />
        </ActionIcon>
      </Group>
    </SimpleGrid>
  );
}
