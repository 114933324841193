import { useForm } from '@mantine/form';
import { noop } from 'lodash';
import { FormProps } from 'components/forms/FormProps';
import useLoadingAction from 'hooks/use-loading-action';
import { SimpleGrid } from '@mantine/core';
import FormInputGroup from 'components/forms/FormInputGroup';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import { useEffect } from 'react';
import { DeviceTypeSelect } from 'components/selects/DeviceTypeSelect';
import FormWrapper from 'components/forms/FormWrapper';
import TextareaWithCharacterCount from 'components/inputs/TextareaWithCharacterCount';
import { PredefinedDataTypeSelect } from 'components/selects/PredefinedDataTypeSelect';
import { IconCheck } from '@tabler/icons-react';
import { useDirtyFormHandler } from 'hooks/use-dirty-form-handler';

/**
 * The data collected from the form.
 */
export interface PreDefinedDataFormData {
  predefinedDataName: string;
  deviceTypeId: string;
  predefinedDataDescription?: string;
  predefinedDataTypeId: string;
  isGlobal?: boolean;
  isPersonal?: boolean;
}

export const preDefinedDataFormInitialValues: PreDefinedDataFormData = {
  predefinedDataName: '',
  deviceTypeId: '',
  predefinedDataDescription: '',
  predefinedDataTypeId: '',
};

export interface PreDefinedDataFormProps extends FormProps<PreDefinedDataFormData> {
  readOnly?: Partial<Record<keyof PreDefinedDataFormData, boolean>>;
  primaryButtonText?: string;
  primaryIcon?: React.ReactNode;
}

/**
 * Form for creating and editing pre-defined data.
 */
export default function PreDefinedDataForm({
  initialValues = preDefinedDataFormInitialValues,
  onSubmit = noop,
  readOnly = {},
  primaryButtonText = 'Uložiť',
  primaryIcon = <IconCheck stroke="1.5" height={24} width={24} />,
}: PreDefinedDataFormProps) {
  const [{ loading }, submit] = useLoadingAction(onSubmit);

  const form = useForm<PreDefinedDataFormData>({
    initialValues,
    validate: {
      predefinedDataName: createValidator([required]),
      deviceTypeId: createValidator([required]),
      predefinedDataTypeId: createValidator([required]),
    },
  });

  useDirtyFormHandler(form);

  useEffect(() => {
    if (!readOnly.deviceTypeId) {
      form.setFieldValue('predefinedDataTypeId', '');
    }
  }, [form.values.deviceTypeId]);

  return (
    <FormWrapper
      onSubmit={form.onSubmit(submit)}
      loading={loading}
      primaryButtonText={primaryButtonText}
      primaryIcon={primaryIcon}
      skipSecondaryButtonConfirm={!form.isDirty()}
    >
      <FormInputGroup groupTitle="Základné info">
        <SimpleGrid cols={2} spacing={40}>
          <TextareaWithCharacterCount
            minRows={3}
            autosize
            maxLength={255}
            label="Názov"
            placeholder="Názov predvoleného údaja"
            size="lg"
            readOnly={readOnly.predefinedDataName}
            name="amperia-predefinedDataName"
            {...form.getInputProps('predefinedDataName')}
          />
          <TextareaWithCharacterCount
            minRows={3}
            autosize
            maxLength={1000}
            label="Popis"
            placeholder="Popis predvoleného údaja"
            size="lg"
            readOnly={readOnly.predefinedDataDescription}
            name="amperia-predefinedDataDescription"
            {...form.getInputProps('predefinedDataDescription')}
          />
        </SimpleGrid>
        <SimpleGrid cols={2} spacing={40}>
          <DeviceTypeSelect
            label="Typ zariadenia"
            size="lg"
            placeholder="Vyberte typ zariadenia"
            searchable
            readOnly={readOnly.deviceTypeId}
            name="amperia-deviceTypeId"
            {...form.getInputProps('deviceTypeId')}
          />
          {form.values.deviceTypeId !== '' && (
            <PredefinedDataTypeSelect
              label="Typ údaja"
              size="lg"
              placeholder="Vyberte typ údaja"
              searchable
              isGlobal={form.values.isGlobal}
              isPersonal={form.values.isPersonal}
              readOnly={readOnly.predefinedDataTypeId}
              deviceTypeId={Number(form.values.deviceTypeId)}
              name="amperia-predefinedDataTypeId"
              {...form.getInputProps('predefinedDataTypeId')}
            />
          )}
        </SimpleGrid>
      </FormInputGroup>
    </FormWrapper>
  );
}
