import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserSetTableProfilesPayload } from 'api/actions/user-set-table-profiles/user-set-table-profiles-payload';
import { UserSetTableProfilesPathParameters } from 'api/actions/user-set-table-profiles/user-set-table-profiles-path-parameters';
import { UserSetTableProfilesResponse } from 'api/actions/user-set-table-profiles/user-set-table-profiles-response';

type UserSetTableProfilesQueryParameters = Record<string, any>;

/**
 * The path of the UserSetTableProfiles action.
 */
export const userSetTableProfilesActionPath = new SweetPath<keyof UserSetTableProfilesPathParameters>(
  '/api/user/table-profiles/{tableId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized UserSetTableProfiles actions.
 */
export default function createUserSetTableProfilesAction(axios: AxiosInstance) {
  return function userSetTableProfilesAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: UserSetTableProfilesPathParameters;
    query?: UserSetTableProfilesQueryParameters;
    payload: UserSetTableProfilesPayload;
  }) {
    const path = userSetTableProfilesActionPath.insert(parameters);

    return axios.put<UserSetTableProfilesResponse, UserSetTableProfilesResponse, UserSetTableProfilesPayload>(
      path,
      payload
    );
  };
}
