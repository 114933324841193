import { useForm } from '@mantine/form';
import { isEmpty, noop, uniq } from 'lodash';
import { FormProps } from 'components/forms/FormProps';
import useLoadingAction from 'hooks/use-loading-action';
import { Box, Button, Group, SimpleGrid, Stack, TextInput, useMantineTheme } from '@mantine/core';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import { IconCheck, IconPlus, IconTrashX } from '@tabler/icons-react';
import FormWrapper from 'components/forms/FormWrapper';
import FormInputGroup from 'components/forms/FormInputGroup';
import { OrganizationSelect } from 'components/selects/OrganizationSelect';
import { DepartmentSelect } from 'components/selects/DepartmentSelect';
import { DeviceTypeSelect } from 'components/selects/DeviceTypeSelect';
import { useEffect, useState } from 'react';
import { RevisionTechnicianSelect } from 'components/selects/RevisionTechnicianSelect';
import P1Regular from 'components/typography/P1Regular';
import P1Medium from 'components/typography/P1Medium';
import { usePickDevices } from 'components/modals/pick-devices-modal/PickDevicesProvider';
import P2Medium from 'components/typography/P2Medium';
import RevisionTermSelect from 'components/inputs/revision-term-select/RevisionTermSelect';
import { DateInput } from 'components/inputs/DateInput';
import { useConfirm } from 'components/modals/message/MessageProvider';
import QuestionMarkTooltip from 'components/QuestionMarkTooltip';
import { getDeviceType } from 'model/DeviceType';
import { useDirtyFormHandler } from 'hooks/use-dirty-form-handler';

/**
 * The data collected from the form.
 */
export interface RevisionFormData {
  revisionName: string;
  deadline: string;
  organizationId: string;
  departmentId: string;
  deviceTypeId: string;
  devices: {
    [deviceId: string]: {
      deviceName: string;
      termId: string;
      deviceSubtypeId: number;
      deviceSubtypeName: string;
      checked: boolean;
      manufacturer?: string;
      type: string;
      serialNumber?: string;
      manufactured?: number;
      internalPossessionsNumber?: string;
      building?: string;
      location?: string;
      organization?: { organizationId: number; organizationName: string };
      revisionPlan?: {
        revisionPlanId: number;
        description: string;
        date?: string;
        revisionPeriod: number;
        neverExecuted: boolean;
      }[];
    };
  };
  technicianId: string;
}

export const revisionFormInitialValues: RevisionFormData = {
  deadline: '',
  revisionName: '',
  organizationId: '',
  departmentId: '',
  deviceTypeId: '',
  devices: {},
  technicianId: '',
};

/**
 * Parameters of the RevisionForm component.
 */
export interface RevisionFormProps extends FormProps<RevisionFormData> {
  readOnly?: Partial<Record<keyof RevisionFormData, boolean>>;
  noValueChange?: Partial<Record<keyof RevisionFormData, boolean>>;
  primaryButtonText?: string;
  primaryIcon?: React.ReactNode;
  context: 'create' | 'edit';
}

/**
 * Form for creating and editing revision.
 */
export default function RevisionForm({
  initialValues = revisionFormInitialValues,
  onSubmit = noop,
  readOnly = {},
  noValueChange = {},
  primaryButtonText = 'Uložiť',
  primaryIcon = <IconCheck stroke={1.5} size={24} />,
  context,
}: RevisionFormProps) {
  const theme = useMantineTheme();
  const [{ loading }, submit] = useLoadingAction(onSubmit);
  const { pickDevices } = usePickDevices();
  const { confirm } = useConfirm();
  const [changeDepartmentId, setChangeDepartmentId] = useState<string | null>(null);
  const [changeOrganizationId, setChangeOrganizationId] = useState<string | null>(null);

  const form = useForm<RevisionFormData>({
    initialValues,
    validate: {
      revisionName: createValidator([required]),
      deadline: createValidator([required]),
      organizationId: createValidator([required]),
      departmentId: createValidator([required]),
      deviceTypeId: createValidator([required]),
      technicianId: createValidator([required]),
      devices: (value) => {
        if (Object.values(value).every((device) => !device.checked)) {
          return 'Musíte vybrať aspoň jedno zariadenie.';
        }
      },
    },
  });

  useDirtyFormHandler(form);

  /**
   * Callback for when the user clicks on the "Pridať zariadenia".
   */
  const addDevices = () => {
    pickDevices({
      title: 'Pridať zariadenia',
      hiddenDevices: uniq([
        ...Object.keys(form.values.devices)
          .filter((deviceId) => form.values.devices[Number(deviceId)].checked)
          .map(Number),
      ]),
      deviceTypeId: Number(form.values.deviceTypeId),
      departmentId: Number(form.values.departmentId),
      onPick: ({ pickedDevices }) => {
        Object.keys(pickedDevices).forEach((deviceId) => {
          const pickedDevice = pickedDevices[Number(deviceId)];
          form.setFieldValue(`devices.${deviceId}`, pickedDevice);
        });
      },
    });
  };

  /**
   * Confirms the action to delete the device from the revision.
   */
  const confirmDeleteDevice = (deviceId: string) => {
    confirm({
      title: 'Odstránenie zariadenia z revíznej správy',
      content: 'Naozaj chcete odstrániť toto zariadenie z revíznej správy? Táto akcia je nevratná.',
      onConfirm: () => {
        form.setFieldValue(`devices.${deviceId}.checked`, false);
      },
    });
  };

  useEffect(() => {
    if (!readOnly.deviceTypeId || !noValueChange.deviceTypeId) {
      form.setFieldValue('deviceTypeId', '');
    }
  }, [form.values.departmentId]);

  useEffect(() => {
    if (readOnly.technicianId === false || !noValueChange.technicianId) {
      form.setFieldValue('technicianId', '');
    }
  }, [JSON.stringify(form.values.deviceTypeId)]);

  useEffect(() => {
    if (changeDepartmentId) {
      confirm({
        title: 'Naozaj chcete zmeniť stredisko?',
        content: 'Zmena strediska zmaže všetky vybrané zariadenia. Naozaj chcete pokračovať?',
        returnFocus: false,
        onConfirm: () => {
          form.getInputProps('departmentId').onChange(changeDepartmentId);
          form.setFieldValue('devices', {});
        },
        onClose: () => setChangeDepartmentId(null),
      });
    }
  }, [changeDepartmentId]);

  useEffect(() => {
    if (changeOrganizationId) {
      confirm({
        title: 'Naozaj chcete zmeniť organizáciu?',
        content: 'Zmena organizácie zmaže všetky vybrané zariadenia. Naozaj chcete pokračovať?',
        returnFocus: false,
        onConfirm: () => {
          form.getInputProps('organizationId').onChange(changeOrganizationId);
          form.getInputProps('departmentId').onChange('');
          form.setFieldValue('devices', {});
        },
        onClose: () => setChangeOrganizationId(null),
      });
    }
  }, [changeOrganizationId]);

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: '2fr 2fr 1fr',
    gap: 0,
  };

  return (
    <FormWrapper
      onSubmit={form.onSubmit(submit)}
      loading={loading}
      primaryButtonText={primaryButtonText}
      primaryIcon={primaryIcon}
      skipSecondaryButtonConfirm={!form.isDirty()}
    >
      <FormInputGroup groupTitle="Základné informácie">
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Názov"
            placeholder="Názov revíznej správy"
            size="lg"
            name="amperia-revisionName"
            {...form.getInputProps('revisionName')}
          />
          <DateInput
            label="Deadline"
            size="lg"
            placeholder="Deadline revíznej správy (napr. 22.08.2023)"
            {...form.getInputProps('deadline')}
          />
        </SimpleGrid>
        <SimpleGrid cols={2} spacing={40}>
          <OrganizationSelect
            label="Organizácia"
            placeholder="Vyberte organizáciu"
            size="lg"
            showDiscarded={false}
            readOnly={readOnly.organizationId}
            disabled={readOnly.organizationId}
            permissionSlug="assign-revisions"
            name="amperia-organizationId"
            {...form.getInputProps('organizationId')}
            onChange={(val) => {
              if (isEmpty(form.values.devices)) {
                form.getInputProps('organizationId').onChange(val);
                form.getInputProps('departmentId').onChange('');
              } else if (val !== form.values.organizationId) {
                setChangeOrganizationId(val);
              } else {
                // No change
              }
            }}
          />
          {form.values.organizationId !== '' && (
            <DepartmentSelect
              label="Stredisko"
              placeholder="Vyberte stredisko"
              size="lg"
              showDiscarded={false}
              organizationId={Number(form.values.organizationId)}
              readOnly={readOnly.departmentId}
              disabled={readOnly.departmentId}
              permissionSlug="assign-revisions"
              name="amperia-departmentId"
              value={changeDepartmentId ?? form.values.departmentId}
              onChange={(val) => {
                if (isEmpty(form.values.devices)) {
                  form.getInputProps('departmentId').onChange(val);
                } else if (val !== form.values.departmentId) {
                  setChangeDepartmentId(val);
                } else {
                  // No change
                }
              }}
            />
          )}
        </SimpleGrid>
        {form.values.organizationId !== '' && form.values.departmentId !== '' && (
          <SimpleGrid cols={2} spacing={40}>
            <DeviceTypeSelect
              label="Zariadenia"
              activeOnly={true}
              departmentId={Number(form.values.departmentId)}
              placeholder={
                readOnly.deviceTypeId ? getDeviceType(Number(form.values.deviceTypeId))?.name : 'Vyberte typ zariadenia'
              }
              size="lg"
              readOnly={readOnly.deviceTypeId}
              disabled={readOnly.deviceTypeId}
              name="amperia-deviceTypeId"
              {...form.getInputProps('deviceTypeId')}
            />

            {form.values.deviceTypeId !== '' && (
              <RevisionTechnicianSelect
                label="Revízny technik"
                placeholder="Vyberte revízneho technika"
                size="lg"
                readOnly={readOnly.technicianId}
                disabled={readOnly.technicianId}
                departmentId={Number(form.values.departmentId)}
                deviceTypeId={Number(form.values.deviceTypeId)}
                name="amperia-technicianId"
                {...form.getInputProps('technicianId')}
              />
            )}
          </SimpleGrid>
        )}
      </FormInputGroup>

      {form.values.deviceTypeId !== '' && form.values.departmentId !== '' && (
        <FormInputGroup groupTitle="Výber zariadení">
          {Object.values(form.values.devices).every((device) => !device.checked) ? (
            <Stack spacing={8} align="flex-start">
              <P1Regular color="gray.6">Zatiaľ ste nevybrali žiadne zariadenia.</P1Regular>
              <Button variant="subtle" onClick={addDevices} leftIcon={<IconPlus stroke="1.5" />}>
                Pridať
              </Button>
              <P2Medium color="red.6">{form.errors.devices}</P2Medium>
            </Stack>
          ) : (
            <Stack spacing={24}>
              <Stack spacing={0}>
                <Group style={{ ...gridStyle, borderRadius: '4px' }} bg="gray.0">
                  <Box p={16}>
                    <P1Medium>Názov</P1Medium>
                  </Box>
                  <Box p={16}>
                    <P1Medium>Termín</P1Medium>
                  </Box>
                  <Box p={16} />
                </Group>
                {Object.keys(form.values.devices).map((deviceId) => {
                  const deviceEntry = form.values.devices[deviceId];

                  return (
                    deviceEntry.checked && (
                      <Group
                        key={deviceId}
                        style={{ ...gridStyle, borderBottom: `1px solid ${theme.colors.gray[1]}` }}
                        spacing={0}
                        h={72}
                      >
                        <Box px={16}>
                          <P1Regular>{deviceEntry.deviceName}</P1Regular>
                        </Box>
                        <Group spacing={8} px={16}>
                          {/* TODO the device did not have any terms when the revision was created */}
                          {/* then the device was edited and the user wanted to edit the revision  */}
                          {/* this select automatically selected the term even though the user might */}
                          {/* not wanted to */}
                          <RevisionTermSelect
                            w="calc(100% - 24px)"
                            autoSelectSingleResult={context === 'create'}
                            options={deviceEntry.revisionPlan}
                            name={`amperia-devices.${deviceId}.termId`}
                            placeholder="Bez termínu"
                            clearable
                            {...form.getInputProps(`devices.${deviceId}.termId`)}
                          />
                          <QuestionMarkTooltip
                            multiline
                            width={275}
                            label="Vyberte termín zariadenia, pre ktorý sa má vykonať revízia alebo nechajte bez termínu ak ide o mimoriadnu revíziu."
                          />
                        </Group>
                        <Box px={10} style={{ justifySelf: 'end' }}>
                          <Button
                            leftIcon={<IconTrashX stroke="1.5" />}
                            variant="danger-secondary"
                            onClick={() => confirmDeleteDevice(deviceId)}
                            disabled={readOnly.devices}
                          >
                            Odstrániť
                          </Button>
                        </Box>
                      </Group>
                    )
                  );
                })}
              </Stack>
              {!readOnly.devices && (
                <Box>
                  <Button variant="subtle" onClick={addDevices} leftIcon={<IconPlus stroke="1.5" />}>
                    Pridať
                  </Button>
                </Box>
              )}
            </Stack>
          )}
        </FormInputGroup>
      )}
    </FormWrapper>
  );
}
