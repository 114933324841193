import { useForm } from '@mantine/form';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import emailFormat from 'components/forms/validators/rules/rule-email-format';
import { isEmpty, noop } from 'lodash';
import { Anchor, Box, Button, Group, PasswordInput, SimpleGrid, TextInput } from '@mantine/core';
import { FormMessage, FormProps } from 'components/forms/FormProps';
import useLoadingAction from 'hooks/use-loading-action';
import FormInputGroup from 'components/forms/FormInputGroup';
import { RoleSelect } from 'components/selects/RoleSelect';
import { OrganizationSelect } from 'components/selects/OrganizationSelect';
import FormWrapper from 'components/forms/FormWrapper';
import passwordFormat from 'components/forms/validators/rules/rule-password-format';
import AdminChangePasswordForm, { AdminChangePasswordFormData } from 'components/forms/user/AdminChangePasswordForm';
import { IconCheck, IconPlus } from '@tabler/icons-react';
import P1Regular from 'components/typography/P1Regular';
import P1RegularLink from 'components/typography/P1RegularLink';
import PermissionsForEntity from 'components/PermissionsForEntity';
import { usePickOrganizations } from 'components/modals/pick-organizations-modal/PickOrganizationsProvider';
import { usePickDepartments } from 'components/modals/pick-departments-modal/PickDepartmentsProvider';
import { useApi } from 'api/api-context';
import { useEffect } from 'react';
import { ROLE_ADMIN_ID, ROLE_USER_ID } from 'model/Role';
import { useDirtyFormHandler } from 'hooks/use-dirty-form-handler';

/**
 * Data collected from the user form.
 */
export interface UserFormData {
  organizationId: string;
  roleId: string;
  preNominals: string;
  postNominals: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  inOrganizations: {
    [organizationId: number]: {
      permissions: number[];
      templateName: string;
      checked: boolean;
      organizationName: string;
    };
  };
  inDepartments: {
    [departmentId: number]: { permissions: number[]; templateName: string; checked: boolean; departmentName: string };
  };
}

/**
 * Initial values of the user form.
 */
export const userFormInitialValues: UserFormData = {
  preNominals: '',
  postNominals: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  password: '',
  confirmPassword: '',
  organizationId: '',
  roleId: '',
  inOrganizations: {},
  inDepartments: {},
};

/**
 * Parameters of the UserForm component.
 */
export interface UserFormProps extends FormProps<UserFormData> {
  hideSections?: Partial<{
    password?: boolean;
    inOrganizations?: boolean;
    inDepartments?: boolean;
  }>;
  onPasswordChange?: (data: AdminChangePasswordFormData) => void;
  passwordChangeMessage?: FormMessage;
  primaryButtonText?: string;
  primaryIcon?: React.ReactNode;
  readOnly?: Partial<Record<keyof UserFormData, boolean>>;
}

/**
 * Form used to create a new user or update an existing user.
 */
export default function UserForm({
  initialValues = userFormInitialValues,
  onSubmit = noop,
  onPasswordChange = noop,
  passwordChangeMessage,
  hideSections = {},
  primaryButtonText = 'Uložiť',
  primaryIcon = <IconCheck stroke="1.5" height={24} width={24} />,
  readOnly = {},
}: UserFormProps = {}) {
  const [{ loading }, submit] = useLoadingAction(onSubmit);
  const { role, roleId } = useApi();
  const { pickOrganizations } = usePickOrganizations();
  const { pickDepartments } = usePickDepartments();

  const form = useForm<UserFormData>({
    initialValues,
    validate: {
      firstName: createValidator([required]),
      lastName: createValidator([required]),
      email: createValidator([required, emailFormat]),
      password: hideSections.password ? undefined : createValidator([required, passwordFormat]),
      confirmPassword: hideSections.password
        ? undefined
        : (confirmPassword, { password }) => {
            if (!confirmPassword) {
              return 'Pole je povinné';
            }

            if (confirmPassword !== password) {
              return 'Heslá sa nezhodujú';
            }

            return null;
          },
      organizationId: createValidator([required]),
      roleId: createValidator([required]),
    },
  });

  useDirtyFormHandler(form);

  /**
   * Callback for when user clicks on the "Pridať oprávnenia v organizáciách" button.
   */
  const assignOrganizations = () => {
    pickOrganizations({
      title: 'Pridať oprávenia v organizáciach',
      hiddenOrganizations: Object.keys(form.values.inOrganizations)
        .filter((organizationId) => form.values.inOrganizations?.[Number(organizationId)].checked)
        .map(Number),
      onPick: ({ pickedOrganizations, permissions, templateName }) => {
        Object.keys(pickedOrganizations).forEach((organizationId: string) => {
          const pickedOrganization = pickedOrganizations[Number(organizationId)];

          form.setFieldValue(`inOrganizations.${organizationId}`, {
            checked: pickedOrganization.checked,
            permissions,
            templateName,
            organizationName: pickedOrganization.organizationName,
          });
        });
      },
      initialTemplateName: 'no-template',
      initialPermissions: [],
      pickOrganizations: true,
      pickPermissions: true,
    });
  };

  /**
   * Callback for when user clicks on the "Pridať oprávnenia v strediskách" button.
   */
  const assignDepartments = () => {
    pickDepartments({
      title: 'Pridať oprávenia v strediskách',
      hiddenDepartments: Object.keys(form.values.inDepartments)
        .filter((departmentId) => form.values.inDepartments?.[Number(departmentId)].checked)
        .map(Number),
      onPick: ({ pickedDepartments, permissions, templateName }) => {
        Object.keys(pickedDepartments).forEach((departmentId: string) => {
          const pickedDepartment = pickedDepartments[Number(departmentId)];

          form.setFieldValue(`inDepartments.${departmentId}`, {
            checked: pickedDepartment.checked,
            permissions,
            templateName,
            departmentName: pickedDepartment.departmentName,
          });
        });
      },
      initialTemplateName: 'no-template',
      initialPermissions: [],
      pickDepartments: true,
      pickPermissions: true,
    });
  };

  // non-admin users can only create users with role "Používateľ"
  useEffect(() => {
    if (role !== 'Admin') {
      form.setFieldValue('roleId', String(ROLE_USER_ID));
    }
  }, [role]);

  return (
    <FormWrapper
      onSubmit={form.onSubmit(submit)}
      loading={loading}
      primaryButtonText={primaryButtonText}
      primaryIcon={primaryIcon}
      skipSecondaryButtonConfirm={!form.isDirty()}
    >
      <FormInputGroup groupTitle="Prístupové práva">
        <SimpleGrid cols={2} spacing={40}>
          <OrganizationSelect
            label="Organizácia"
            size="lg"
            placeholder="Vyberte organizáciu"
            showDiscarded={false}
            readOnly={readOnly.organizationId}
            disabled={readOnly.organizationId}
            permissionSlug="manage-users"
            autoSelectSingleResult
            name="amperia-organizationId"
            {...form.getInputProps('organizationId')}
          />
          {role === 'Admin' && (
            <RoleSelect
              label="Rola"
              size="lg"
              placeholder="Vyberte rolu"
              name="amperia-roleId"
              {...form.getInputProps('roleId')}
            />
          )}
        </SimpleGrid>
      </FormInputGroup>
      <FormInputGroup groupTitle="Osobné údaje">
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            size="lg"
            label="Titul pred menom"
            placeholder="Titul pred menom"
            name="amperia-preNominals"
            {...form.getInputProps('preNominals')}
          />
          <TextInput
            size="lg"
            label="Titul za menom"
            placeholder="Titul za menom"
            name="amperia-postNominals"
            {...form.getInputProps('postNominals')}
          />
        </SimpleGrid>
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            size="lg"
            label="Meno"
            placeholder="Meno"
            name="amperia-firstName"
            {...form.getInputProps('firstName')}
          />
          <TextInput
            size="lg"
            label="Priezvisko"
            placeholder="Priezvisko"
            name="amperia-lastName"
            {...form.getInputProps('lastName')}
          />
        </SimpleGrid>
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            size="lg"
            type="email"
            label="E-mail"
            placeholder="Emailová adresa"
            name="amperia-email"
            {...form.getInputProps('email')}
          />
          <TextInput
            size="lg"
            label="Telefón"
            type="tel"
            placeholder="Telefónne číslo"
            name="amperia-phoneNumber"
            {...form.getInputProps('phoneNumber')}
          />
        </SimpleGrid>
      </FormInputGroup>

      {/* Hidden in the case of Edit displayed in case of Add */}
      {!hideSections.password && (
        <FormInputGroup groupTitle="Heslo">
          <SimpleGrid cols={2} spacing={40}>
            <PasswordInput
              label="Heslo"
              placeholder="Zadajte heslo"
              size="lg"
              name="amperia-password"
              {...form.getInputProps('password')}
            />
            <PasswordInput
              label="Potvrďte heslo"
              placeholder="Zadajte heslo znova"
              size="lg"
              name="amperia-confirmPassword"
              {...form.getInputProps('confirmPassword')}
            />
          </SimpleGrid>
        </FormInputGroup>
      )}

      {/* Displayed in the case of Edit and hidden in case of Add */}
      {hideSections.password && roleId === ROLE_ADMIN_ID && (
        <AdminChangePasswordForm onSubmit={onPasswordChange} message={passwordChangeMessage} />
      )}
      {form.values.roleId !== String(ROLE_ADMIN_ID) && (
        <>
          <FormInputGroup groupTitle="Používateľ v organizáciách">
            {(isEmpty(form.values.inOrganizations) ||
              Object.keys(form.values.inOrganizations)
                .map((organizationId) => form.values.inOrganizations[Number(organizationId)].checked)
                .every((checked) => !checked)) && (
              <Group pt={26} spacing={8}>
                <P1Regular color="gray.6">Používateľ zatiaľ nemá oprávnenia žiadnych organizáciách.</P1Regular>
                <Anchor onClick={assignOrganizations}>
                  <P1RegularLink span color="blue.8">
                    Priraďte používateľovi práva v organizáciách
                  </P1RegularLink>
                </Anchor>
              </Group>
            )}

            <SimpleGrid cols={2} spacing={40} verticalSpacing={24}>
              {Object.keys(form.values.inOrganizations).map((organizationId) => {
                const organizationEntry = form.values.inOrganizations[organizationId as any];

                return (
                  organizationEntry.checked && (
                    <PermissionsForEntity
                      key={organizationId}
                      entityId={Number(organizationId)}
                      entityName={organizationEntry.organizationName}
                      entityType="organization"
                      permissions={organizationEntry.permissions}
                      templateSlug={organizationEntry.templateName}
                      {...form.getInputProps('inOrganizations')}
                      updatePermissionsForEntity={({ permissions, templateName }) => {
                        form.setFieldValue(`inOrganizations.${organizationId}.permissions`, permissions);

                        form.setFieldValue(`inOrganizations.${organizationId}.templateName`, templateName);
                      }}
                      onEntityDelete={(entityId) => {
                        form.setFieldValue(`inOrganizations.${entityId}`, {
                          checked: false,
                          permissions: [],
                        });
                      }}
                    />
                  )
                );
              })}
            </SimpleGrid>
          </FormInputGroup>

          {!isEmpty(form.values.inOrganizations) &&
            Object.keys(form.values.inOrganizations)
              .map((organizationId) => form.values.inOrganizations[Number(organizationId)].checked)
              .some((checked) => checked) && (
              <Box pl={40} pb={24}>
                <Button size="md" variant="subtle" leftIcon={<IconPlus stroke="1.5" />} onClick={assignOrganizations}>
                  Pridať oprávnenia v organizáciách
                </Button>
              </Box>
            )}
        </>
      )}

      {form.values.roleId !== String(ROLE_ADMIN_ID) && (
        <>
          <FormInputGroup groupTitle="Používateľ v strediskách">
            {isEmpty(form.values.inDepartments) && (
              <Group pt={26} spacing={8}>
                <P1Regular color="gray.6">Používateľ zatiaľ nemá oprávnenia v žiadnych strediskách.</P1Regular>
                <Anchor onClick={assignDepartments}>
                  <P1RegularLink span color="blue.8">
                    Pridať oprávnenia v strediskách
                  </P1RegularLink>
                </Anchor>
              </Group>
            )}

            <SimpleGrid cols={2} spacing={40} verticalSpacing={24}>
              {Object.keys(form.values.inDepartments).map((departmentId) => {
                const departmentEntry = form.values.inDepartments[departmentId as any];

                return (
                  departmentEntry.checked && (
                    <PermissionsForEntity
                      key={departmentId}
                      entityId={Number(departmentId)}
                      entityName={departmentEntry.departmentName}
                      entityType="department"
                      permissions={departmentEntry.permissions}
                      templateSlug={departmentEntry.templateName}
                      {...form.getInputProps('inDepartments')}
                      updatePermissionsForEntity={({ permissions, templateName }) => {
                        form.setFieldValue(`inDepartments.${departmentId}.permissions`, permissions);

                        form.setFieldValue(`inDepartments.${departmentId}.templateName`, templateName);
                      }}
                      onEntityDelete={(entityId) => {
                        form.setFieldValue(`inDepartments.${entityId}`, {
                          checked: false,
                          permissions: [],
                        });
                      }}
                    />
                  )
                );
              })}
            </SimpleGrid>
          </FormInputGroup>

          {!isEmpty(form.values.inDepartments) &&
            Object.keys(form.values.inDepartments)
              .map((departmentId) => form.values.inDepartments![Number(departmentId)].checked)
              .some((checked) => checked) && (
              <Box pl={40} pb={24}>
                <Button size="md" variant="subtle" leftIcon={<IconPlus stroke="1.5" />} onClick={assignDepartments}>
                  Pridať oprávnenia v strediskách
                </Button>
              </Box>
            )}
        </>
      )}
    </FormWrapper>
  );
}
