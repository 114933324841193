import { useForm } from '@mantine/form';
import { noop } from 'lodash';
import { FormProps } from 'components/forms/FormProps';
import useLoadingAction from 'hooks/use-loading-action';
import { SimpleGrid, Textarea, TextInput } from '@mantine/core';
import FormInputGroup from 'components/forms/FormInputGroup';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import FormWrapper from 'components/forms/FormWrapper';
import { IconCheck } from '@tabler/icons-react';
import { useDirtyFormHandler } from 'hooks/use-dirty-form-handler';

/**
 * The data collected from the form.
 */
export interface MeasuringDeviceFormData {
  deviceName: string;
  manufacturer: string;
  type: string;
  serialNumber: string;
  deviceInfo?: string;
}

/**
 * Parameters of the MeasuringDeviceForm.
 */
export interface MeasuringDeviceFormProps extends FormProps<MeasuringDeviceFormData> {
  readOnly?: Partial<Record<keyof MeasuringDeviceFormData, boolean>>;
  primaryButtonText?: string;
  primaryIcon?: React.ReactNode;
}

/**
 * Form for creating and editing measuring device.
 */
export default function MeasuringDeviceForm({
  initialValues = {
    deviceName: '',
    manufacturer: '',
    type: '',
    serialNumber: '',
    deviceInfo: '',
  },
  onSubmit = noop,
  primaryButtonText = 'Uložiť',
  primaryIcon = <IconCheck stroke="1.5" height={24} width={24} />,
}: MeasuringDeviceFormProps) {
  const [{ loading }, submit] = useLoadingAction(onSubmit);

  const form = useForm<MeasuringDeviceFormData>({
    initialValues,
    validate: {
      deviceName: createValidator([required]),
      manufacturer: createValidator([required]),
      type: createValidator([required]),
      serialNumber: createValidator([required]),
    },
  });

  useDirtyFormHandler(form);

  return (
    <FormWrapper
      onSubmit={form.onSubmit(submit)}
      loading={loading}
      primaryButtonText={primaryButtonText}
      primaryIcon={primaryIcon}
      skipSecondaryButtonConfirm={!form.isDirty()}
    >
      <FormInputGroup groupTitle="Základné info">
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Názov"
            placeholder="Názov"
            size="lg"
            name="amperia-deviceName"
            {...form.getInputProps('deviceName')}
          />
          <TextInput
            label="Výrobca"
            placeholder="Výrobca"
            size="lg"
            name="amperia-manufacturer"
            {...form.getInputProps('manufacturer')}
          />
        </SimpleGrid>
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Typ"
            placeholder="Typ zariadenia"
            size="lg"
            name="amperia-type"
            {...form.getInputProps('type')}
          />
          <TextInput
            label="Výrobné číslo"
            placeholder="Výrobné číslo"
            size="lg"
            name="amperia-serialNumber"
            {...form.getInputProps('serialNumber')}
          />
        </SimpleGrid>
        <SimpleGrid cols={2} spacing={40}>
          <Textarea
            minRows={3}
            autosize
            label="Info"
            placeholder="Info o zariadení"
            size="lg"
            name="amperia-deviceInfo"
            {...form.getInputProps('deviceInfo')}
          />
        </SimpleGrid>
      </FormInputGroup>
    </FormWrapper>
  );
}
