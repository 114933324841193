import { ColDef } from 'ag-grid-community';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DepartmentSelectFilter from 'components/tables/filters/DepartmentSelectFilter';
import DeviceTypeSelectFilter from 'components/tables/filters/DeviceTypeSelectFilter';
import { useApi } from 'api/api-context';
import DataTable from 'components/tables/DataTable';
import { ADD_DEVICE_PAGE_PATH } from 'routes/paths';
import DeviceSubtypeEqualsSelectFilter from 'components/tables/filters/device-subtype/DeviceSubtypeEqualsSelectFilter';
import OrganizationSelectFilter from 'components/tables/filters/OrganizationSelectFilter';
import DeviceFaultsSelectFilter from 'components/tables/filters/DeviceFaultsSelectFilter';
import { ImportCallback, ImportResult } from 'components/tables/ImportModal';
import { DeviceImportResponse } from 'api/actions/device-import/device-import-response';
import { INVALID_XML_FILE_CONTENTS_ERROR_CODE } from 'utils/constants';
import { DeviceRow } from 'components/tables/data/device/types';
import DeviceNameColumn from 'components/tables/data/device/columns/DeviceNameColumn';
import OrganizationNameColumn from 'components/tables/data/device/columns/OrganizationNameColumn';
import DepartmentNameColumn from 'components/tables/data/device/columns/DepartmentNameColumn';
import DeviceTypeNameColumn from 'components/tables/data/device/columns/DeviceTypeNameColumn';
import FaultsColumn from 'components/tables/data/device/columns/FaultsColumn';
import InternalPossessionsNumberColumn from 'components/tables/data/device/columns/InternalPossessionsNumberColumn';
import ActionsColumn from 'components/tables/data/device/columns/ActionsColumn';
import DeviceSubtypeNameColumn from 'components/tables/data/device/columns/DeviceSubtypeNameColumn';
import ResponsiblePersonColumn from 'components/tables/data/device/columns/ResponsiblePersonColumn';
import ExternalIdColumn from 'components/tables/data/device/columns/ExternalIdColumn';
import { useAcknowledge } from 'components/modals/message/MessageProvider';
import ImportHint from 'components/tables/data/device/ImportHint';
import DeviceIdColumn from 'components/tables/data/device/columns/DeviceIdColumn';
import BuildingColumn from 'components/tables/data/device/columns/BuildingColumn';
import FloorColumn from 'components/tables/data/device/columns/FloorColumn';
import RoomColumn from 'components/tables/data/device/columns/RoomColumn';
import ManufacturerColumn from 'components/tables/data/device/columns/ManufacturerColumn';
import SerialNumberColumn from 'components/tables/data/device/columns/SerialNumberColumn';
import ManufacturedColumn from 'components/tables/data/device/columns/ManufacturedColumn';
import { DataTableExport } from 'components/tables/ExportModal';

/**
 * Table which displays devices.
 */
export default function DeviceTable() {
  const { getAction, hasPermissionAnywhere } = useApi();
  const { acknowledge } = useAcknowledge();
  const [searchParams, setSearchParams] = useSearchParams();

  const [evidenceFieldsColumns, setEvidenceFieldsColumns] = useState<ColDef[]>([]);

  /**
   * The columns of the table.
   */
  const columns: ColDef[] = useMemo(
    () => [
      {
        field: 'deviceId',
        headerName: 'ID',
        minWidth: 76,
        sortable: true,
        resizable: false,
        unSortIcon: true,
        wrapText: true,
        cellRenderer: DeviceIdColumn,
      },
      {
        field: 'deviceName',
        headerName: 'Názov zariadenia',
        minWidth: 350,
        sortable: true,
        resizable: true,
        unSortIcon: true,
        wrapText: true,
        cellRenderer: DeviceNameColumn,
      },
      {
        field: 'organization.organizationId',
        headerName: 'ID organizácie',
        hide: true,
        filter: true,
      },
      {
        field: 'organization.organizationName',
        headerName: 'Organizácia',
        minWidth: 275,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: OrganizationSelectFilter.configure({ permissionSlug: 'browse-devices' }),
        resizable: true,
        sortable: true,
        unSortIcon: true,
        wrapText: true,
        cellRenderer: OrganizationNameColumn,
      },
      {
        field: 'department.departmentId',
        headerName: 'ID strediska',
        minWidth: 0,
        maxWidth: 0,
        width: 0,
        cellStyle: { opacity: 0 },
        filter: true,
      },
      {
        field: 'department.departmentName',
        headerName: 'Stredisko',
        minWidth: 275,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: DepartmentSelectFilter.configure({ permissionSlug: 'browse-devices' }),
        resizable: true,
        sortable: true,
        unSortIcon: true,
        wrapText: true,
        cellRenderer: DepartmentNameColumn,
      },
      {
        field: 'deviceType.deviceTypeId',
        hide: true,
        filter: true,
      },
      {
        field: 'deviceType.deviceTypeName',
        headerName: 'Zariadenie',
        minWidth: 240,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: DeviceTypeSelectFilter,
        resizable: true,
        sortable: true,
        unSortIcon: true,
        wrapText: true,
        cellRenderer: DeviceTypeNameColumn,
      },
      {
        field: 'deviceSubtype.deviceTypeId',
        hide: true,
        filter: true,
      },
      {
        field: 'deviceSubtype.deviceTypeName',
        headerName: 'Typ zariadenia',
        minWidth: 250,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: DeviceSubtypeEqualsSelectFilter,
        resizable: true,
        sortable: true,
        unSortIcon: true,
        wrapText: true,
        cellRenderer: DeviceSubtypeNameColumn,
      },
      {
        field: 'faultType',
        hide: true,
        filter: true,
      },
      {
        field: 'hasFaults',
        headerName: 'Má závady',
        hide: true,
        filter: true,
      },
      {
        field: 'hasFaultsTranslation',
        headerName: 'Má závady',
        hide: true,
      },
      {
        field: 'faults',
        headerName: 'Závada',
        minWidth: 150,
        resizable: true,
        wrapText: true,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: DeviceFaultsSelectFilter,
        cellRenderer: FaultsColumn,
      },
      {
        field: 'responsiblePerson.fullName',
        headerName: 'Zodpovedná osoba',
        resizable: true,
        minWidth: 300,
        wrapText: true,
        cellRenderer: ResponsiblePersonColumn,
      },
      {
        field: 'internalPossessionsNumber',
        headerName: 'Int. č. majetku',
        resizable: true,
        minWidth: 160,
        wrapText: true,
        cellRenderer: InternalPossessionsNumberColumn,
      },
      {
        field: 'building',
        headerName: 'Budova',
        minWidth: 250,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        wrapText: true,
        cellRenderer: BuildingColumn,
      },
      {
        field: 'floor',
        headerName: 'Podlažie',
        minWidth: 250,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        wrapText: true,
        cellRenderer: FloorColumn,
      },
      {
        field: 'room',
        headerName: 'Miestnosť',
        minWidth: 250,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        wrapText: true,
        cellRenderer: RoomColumn,
      },
      { field: 'longitude', headerName: 'Zemepisná dĺžka', hide: true },
      { field: 'latitude', headerName: 'Zemepisná šírka', hide: true },
      {
        valueGetter: ({ data: { status } }: { data: DeviceRow }) => (status ? '1' : '0'),
        field: 'status',
        headerName: 'Aktívna',
        filter: true,
        minWidth: 0,
        maxWidth: 0,
        width: 0,
        cellStyle: { opacity: 0 },
      },
      {
        field: 'externalId',
        headerName: 'Externé ID',
        resizable: true,
        minWidth: 160,
        wrapText: true,
        cellRenderer: ExternalIdColumn,
      },
      {
        field: 'manufacturer',
        headerName: 'Výrobca',
        minWidth: 250,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        wrapText: true,
        cellRenderer: ManufacturerColumn,
      },
      {
        field: 'serialNumber',
        headerName: 'Výrobné číslo',
        minWidth: 200,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        wrapText: true,
        cellRenderer: SerialNumberColumn,
      },
      {
        field: 'manufactured',
        headerName: 'Rok výroby',
        minWidth: 150,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        wrapText: true,
        cellRenderer: ManufacturedColumn,
      },
      { field: 'responsiblePersonNote', headerName: 'Poznámka k zodpovednej osobe', hide: true },
      { field: 'internalNote', headerName: 'Poznámka k int. číslu majetku', hide: true },
      {
        field: 'isDiscarded',
        headerName: 'Vyradené',
        hide: true,
      },
      {
        field: 'lastRevision.revisionId',
        headerName: 'ID poslednej revízie',
        hide: true,
      },
      {
        field: 'lastRevision.revisionName',
        headerName: 'Názov poslednej revízie',
        hide: true,
      },
      {
        field: 'inletCount',
        headerName: 'Počet prívodov',
        hide: true,
      },
      {
        field: 'outletCount',
        headerName: 'Počet vývodov',
        hide: true,
      },
      {
        field: 'importId',
        headerName: 'ID importu',
        filter: true,
        minWidth: 0,
        maxWidth: 0,
        width: 0,
        cellStyle: { opacity: 0 },
      },
      {
        field: '_actions',
        headerName: '',
        pinned: 'right',
        width: 194,
        minWidth: 194,
        maxWidth: 194,
        cellRenderer: ActionsColumn,
      },
      ...evidenceFieldsColumns,
    ],
    [evidenceFieldsColumns]
  );

  const exportColumns = useMemo(
    () => [
      'deviceId',
      'deviceName',
      'organization.organizationId',
      'organization.organizationName',
      'department.departmentId',
      'department.departmentName',
      'deviceType.deviceTypeName',
      'deviceSubtype.deviceTypeName',
      'hasFaultsTranslation',
      'internalPossessionsNumber',
      'internalNote',
      'building',
      'room',
      'floor',
      'longitude',
      'latitude',
      'responsiblePerson.fullName',
      'externalId',
      'manufacturer',
      'serialNumber',
      'manufactured',
      'responsiblePersonNote',
      'isDiscarded',
      'lastRevision.revisionId',
      'lastRevision.revisionName',
      'inletCount',
      'outletCount',
      ...evidenceFieldsColumns.map(({ field }) => field ?? '').filter(Boolean),
    ],
    [evidenceFieldsColumns]
  );

  const dataExport: DataTableExport = useMemo(
    () => ({
      modalTitle: 'Exportovať zariadenia',
      fileName: 'zariadenia.xlsx',
      columnKeys: exportColumns,
      initialSelectedColumns: exportColumns.filter((column) => !column.startsWith('evidenceFields.')),
    }),
    [exportColumns]
  );

  const [initialFilters] = useState(() => {
    const filters: Record<string, any> = {};

    if (searchParams.has('organizationId')) {
      filters['organization.organizationId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('organizationId'),
      };
    }

    if (searchParams.has('departmentId')) {
      filters['department.departmentId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('departmentId'),
      };
    }

    if (searchParams.has('deviceTypeId')) {
      filters['deviceType.deviceTypeId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('deviceTypeId'),
      };
    }

    if (searchParams.has('faultType')) {
      filters.faultType = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('faultType'),
      };
    }

    if (searchParams.has('importId')) {
      filters.importId = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('importId'),
      };
    }

    return filters;
  });

  const displayImportHint = useCallback(
    () =>
      acknowledge({
        title: 'Import zariadení',
        content: ImportHint,
        zIndex: 300,
      }),
    [acknowledge]
  );

  const action = useCallback(async () => {
    const action = getAction('DeviceList');

    const devices = await action();

    const evidenceFieldMap = new Map<string, string>();

    const augmentedDevices = devices.map((device) => {
      const evidenceFields = device.evidenceFields?.reduce((acc, field) => {
        if (!field.fieldName || !field.evidenceFieldId) {
          return acc;
        }

        const key = String(field.evidenceFieldId);
        const value = (field.fieldValue || field.fieldNumberValue || field.fieldDateValue || '').toString();

        evidenceFieldMap.set(key, field.fieldName);

        acc[key] = value;
        return acc;
      }, {} as Record<string, string>);

      return {
        ...device,
        faultType: device.hasUnfixedFaults ? 'with-faults' : 'no-faults',
        hasFaultsTranslation: device.hasUnfixedFaults ? 'Áno' : 'Nie',
        isDiscarded: device.status ? 'Nie' : 'Áno',
        evidenceFields,
      };
    });

    const evidenceFieldsColumns = Array.from(evidenceFieldMap).map(([key, name]) => ({
      field: `evidenceFields.${key}`,
      headerName: `Evidenčné pole: ${name}`,
      hide: true,
    }));

    setEvidenceFieldsColumns(evidenceFieldsColumns);

    return augmentedDevices;
  }, [getAction]);

  const handleImport: ImportCallback = useCallback(async (file) => {
    if (file.type !== 'text/xml') {
      throw new Error('Súbor musí byť vo formáte XML.');
    }

    const contents = await file.text();

    const deviceImportAction = getAction('DeviceImport');

    let response = [] as ImportResult[] | DeviceImportResponse;

    try {
      response = await deviceImportAction({
        payload: {
          data: contents,
        },
      });
    } catch (e: any) {
      if (e.response.data.error.code === INVALID_XML_FILE_CONTENTS_ERROR_CODE) {
        throw new Error(`XML súbor sa nedá prečítať alebo nevyhovuje schéme. Chyby: ${e.response.data.error.message}`);
      }

      throw e;
    }

    return response as ImportResult[];
  }, []);

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.delete('organizationId');
        params.delete('departmentId');
        params.delete('deviceTypeId');
        params.delete('faultType');
        params.delete('importId');
        return params;
      },
      { replace: true }
    );
  }, []);

  return (
    <DataTable
      tableId="devices"
      title="Zariadenia"
      addButtonText="Pridať nové"
      toggleDiscardedLabel="Zobraziť vyradené"
      columns={columns}
      action={action}
      addButtonTarget={ADD_DEVICE_PAGE_PATH.original}
      hideAddButton={!hasPermissionAnywhere('manage-devices')}
      initialFilters={initialFilters}
      dataExport={dataExport}
      dataImport={
        hasPermissionAnywhere('manage-devices')
          ? {
              modalTitle: 'Importovať zariadenia',
              action: handleImport,
              onHint: displayImportHint,
            }
          : undefined
      }
    />
  );
}
