import { useForm } from '@mantine/form';
import { isEmpty, noop } from 'lodash';
import { FormProps } from 'components/forms/FormProps';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import useLoadingAction from 'hooks/use-loading-action';
import { Anchor, Box, Button, Checkbox, Flex, Group, SimpleGrid, Stack, TextInput } from '@mantine/core';
import { IconCheck, IconPlus, IconTrashX } from '@tabler/icons-react';
import FormInputGroup from 'components/forms/FormInputGroup';
import P1Regular from 'components/typography/P1Regular';
import { usePickDeviceType } from 'components/modals/pick-device-type/PickDeviceTypeProvider';
import { useCallback, useState } from 'react';
import DeviceTypeLabel from 'components/device/DeviceTypeLabel';
import FormWrapper from 'components/forms/FormWrapper';
import P1RegularLink from 'components/typography/P1RegularLink';
import { nanoid } from 'nanoid';
import PersonWithPermissions from 'components/PersonWithPermissions';
import { usePickUsers } from 'components/modals/pick-users-modal/PickUsersProvider';
import { useConfirm } from 'components/modals/message/MessageProvider';
import { useDirtyFormHandler } from 'hooks/use-dirty-form-handler';

/**
 * The data collected from the form.
 */
export interface OrganizationFormData {
  organizationName: string;
  streetAddress: string;
  city: string;
  zip: string;
  country: string;
  selfAdministration: boolean;
  includedInStatistics: boolean;
  inKepHashArchive: boolean;
  companyQualifications: {
    qualificationNumber: string;
    type: string;
    uuid: string;
  }[];
  people: {
    [userId: string]: {
      checked: boolean;
      deleted: boolean;
      permissions: number[];
      templateName: string;
      isNew: boolean;
    };
  };
  peopleInDepartments: {
    [departmentId: string]: { userId: number; permissions: number[]; templateName: string; departmentName: string }[];
  };
}

/**
 * Parameters of the OrganizationForm.
 */
export interface OrganizationFormProps extends FormProps<OrganizationFormData> {
  readOnly?: Partial<Record<keyof OrganizationFormData, boolean>>;
  primaryButtonText?: string;
  primaryIcon?: React.ReactNode;
}

/**
 * Form for creating and editing organization.
 */
export default function OrganizationForm({
  initialValues = {
    organizationName: '',
    streetAddress: '',
    city: '',
    zip: '',
    country: '',
    selfAdministration: false,
    includedInStatistics: true,
    inKepHashArchive: true,
    companyQualifications: [],
    people: {},
    peopleInDepartments: {},
  },
  onSubmit = noop,
  primaryButtonText = 'Uložiť',
  primaryIcon = <IconCheck stroke="1.5" height={24} width={24} />,
}: OrganizationFormProps) {
  const { pickDeviceType } = usePickDeviceType();
  const { confirm } = useConfirm();
  const [{ loading }, submit] = useLoadingAction(onSubmit);
  const { pickUsers } = usePickUsers();

  const [extendedInitialValues] = useState(() => ({
    ...initialValues,
    companyQualifications: initialValues.companyQualifications.map((qualification) => ({
      ...qualification,
      uuid: nanoid(),
    })),
  }));

  const form = useForm({
    initialValues: extendedInitialValues,
    validate: {
      organizationName: createValidator([required]),
      streetAddress: createValidator([required]),
      city: createValidator([required]),
      zip: createValidator([required]),
      country: createValidator([required]),
      companyQualifications: {
        qualificationNumber: createValidator([required]),
      },
    },
  });

  useDirtyFormHandler(form);

  /**
   * Adds a new qualification to the form.
   */
  const addQualification = useCallback(() => {
    pickDeviceType({
      title: 'Pridať oprávnenie organizácie',
      addButtonLabel: 'Pridať opravnenie',
      onPick: ({ slug }) =>
        form.insertListItem('companyQualifications', {
          type: slug,
          qualificationNumber: '',
          uuid: nanoid(),
        }),
    });
  }, [pickDeviceType, form]);

  /**
   * Callback for when user clicks on the "Pridať ľudí" button.
   */
  const addPeopleToOrganization = () => {
    pickUsers({
      title: 'Pridať ľudí do organizácie',
      hiddenUsers: Object.keys(form.values.people)
        .filter((userId) => form.values.people[Number(userId)].checked)
        .map(Number),
      onPick: ({ pickedUsers, permissions, templateName }) => {
        pickedUsers.forEach((userId: number) => {
          const oldChecked = form.values.people[userId]?.checked ?? false;
          const newChecked = true;

          form.setFieldValue(`people.${userId}`, {
            checked: newChecked,
            deleted: false,
            permissions,
            templateName,
            isNew: !oldChecked && newChecked,
          });
        });
      },
      inForm: 'organization',
      initialTemplateName: 'no-template',
      initialPermissions: [],
      pickUsers: true,
      pickPermissions: true,
    });
  };

  /**
   * Removes a qualification from the form.
   */
  const removeQualification = useCallback((i: number) => form.removeListItem('companyQualifications', i), [form]);

  /**
   * Asks the user to confirm the removal of the qualification.
   */
  const removeQualificationConfirm = useCallback(
    (index: number) => {
      const hasValue = !!form.values.companyQualifications[index].qualificationNumber;

      if (hasValue) {
        confirm({
          title: 'Zmazať oprávnenie',
          content: 'Naozaj chcete zmazať oprávnenie?',
          onConfirm: () => removeQualification(index),
        });
      } else {
        removeQualification(index);
      }
    },
    [removeQualification, confirm, form]
  );

  return (
    <FormWrapper
      onSubmit={form.onSubmit(submit)}
      loading={loading}
      primaryButtonText={primaryButtonText}
      primaryIcon={primaryIcon}
      skipSecondaryButtonConfirm={!form.isDirty()}
    >
      <FormInputGroup groupTitle="Základné info">
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Názov"
            placeholder="Názov organizácie"
            size="lg"
            name="amperia-organizationName"
            {...form.getInputProps('organizationName')}
          />
          <TextInput
            label="Adresa"
            placeholder="Ulica a číslo"
            size="lg"
            name="9d92258e-aeb6-4aac-b782-e7d453ab7b46"
            {...form.getInputProps('streetAddress')}
          />
        </SimpleGrid>
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Mesto"
            placeholder="Mesto"
            size="lg"
            name="fc6c1476-34b6-48f0-ba9f-864a274053a6"
            {...form.getInputProps('city')}
          />
          <TextInput
            label="PSČ"
            placeholder="PSČ"
            size="lg"
            name="8711e7d8-cb6a-4161-bada-c3b289968b04"
            {...form.getInputProps('zip')}
          />
        </SimpleGrid>
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Štát"
            placeholder="Štát"
            size="lg"
            name="2e96e9b9-e51b-4405-bb21-0f14f3a94b67"
            {...form.getInputProps('country')}
          />
        </SimpleGrid>
      </FormInputGroup>

      <FormInputGroup groupTitle="Nastavenia">
        <Stack spacing={16} py={6}>
          <Checkbox
            size="md"
            label="Organizácia sa spravuje sama"
            name="amperia-selfAdministration"
            {...form.getInputProps('selfAdministration', { type: 'checkbox' })}
          />
          <Checkbox
            size="md"
            name="amperia-includedInStatistics"
            label="Rátať do štatistík"
            {...form.getInputProps('includedInStatistics', { type: 'checkbox' })}
          />
          <Checkbox
            size="md"
            name="amperia-inKepHashArchive"
            label="KEP hash archív"
            {...form.getInputProps('inKepHashArchive', { type: 'checkbox' })}
          />
        </Stack>
      </FormInputGroup>

      {isEmpty(form.values.companyQualifications) && (
        <FormInputGroup groupTitle="Oprávnenia">
          <Group py={26} spacing={8}>
            <P1Regular color="gray.6">Organizácia zatiaľ nemá priradené žiadne oprávnenia.</P1Regular>
            <Anchor onClick={addQualification}>
              <P1RegularLink span color="blue.8">
                Pridať oprávnenia
              </P1RegularLink>
            </Anchor>
          </Group>
        </FormInputGroup>
      )}

      {!isEmpty(form.values.companyQualifications) && (
        <FormInputGroup groupTitle="Oprávnenia" stackProps={{ pb: 16 }} />
      )}

      {(form.values.companyQualifications || []).map((qualification, index) => (
        <Flex gap={32} key={qualification.uuid} pl={40} pb={40}>
          <Box w={8} bg="gray.0" />
          <Stack w="100%" spacing={24}>
            <DeviceTypeLabel deviceType={qualification.type} size="md" />
            <SimpleGrid cols={2} spacing={40}>
              <TextInput
                label="Číslo oprávnenia"
                placeholder="Zadajte číslo oprávnenia"
                size="lg"
                name={`amperia-companyQualifications.${index}.qualificationNumber`}
                {...form.getInputProps(`companyQualifications.${index}.qualificationNumber`)}
              />
              <Group mt={27} sx={{ transform: 'translateX(-32px)' }}>
                <Button
                  type="button"
                  variant="danger-secondary"
                  leftIcon={<IconTrashX stroke="1.5" size={24} />}
                  size="lg"
                  onClick={() => removeQualificationConfirm(index)}
                >
                  Zmazať
                </Button>
              </Group>
            </SimpleGrid>
          </Stack>
        </Flex>
      ))}

      {!isEmpty(form.values.companyQualifications) && (
        <Stack align="flex-start" pl={40} pb={20}>
          <Button variant="subtle" size="md" leftIcon={<IconPlus stroke="1.5" />} onClick={addQualification}>
            Pridať oprávnenie
          </Button>
        </Stack>
      )}

      <FormInputGroup groupTitle="Ľudia v organizácii">
        {(isEmpty(form.values.people) ||
          Object.keys(form.values.people)
            .map((userId) => form.values.people[Number(userId)].checked)
            .every((checked) => !checked)) && (
          <Group pt={26} spacing={8}>
            <P1Regular color="gray.6">Organizácia zatiaľ nemá priradených žiadnych ľudí.</P1Regular>
            <Anchor onClick={addPeopleToOrganization}>
              <P1RegularLink span color="blue.8">
                Pridať ľudí
              </P1RegularLink>
            </Anchor>
          </Group>
        )}

        <SimpleGrid cols={2} spacing={40} verticalSpacing={24}>
          {Object.keys(form.values.people).map((personId) => {
            const userEntry = form.values.people[personId];

            return (
              userEntry.checked && (
                <PersonWithPermissions
                  key={personId}
                  userId={Number(personId)}
                  permissions={userEntry.permissions}
                  templateSlug={userEntry.templateName}
                  inForm="organization"
                  updatePermissionsForPerson={({ permissions, templateName }) => {
                    form.setFieldValue(`people.${personId}.permissions`, permissions);
                    form.setFieldValue(`people.${personId}.templateName`, templateName);
                  }}
                  {...form.getInputProps('people')}
                  onUserDelete={(userId) => {
                    form.setFieldValue(`people.${userId}`, {
                      checked: false,
                      permissions: [],
                      deleted: true,
                      isNew: false,
                    });
                  }}
                />
              )
            );
          })}
        </SimpleGrid>
      </FormInputGroup>

      {!isEmpty(form.values.people) &&
        Object.keys(form.values.people)
          .map((userId) => form.values.people[Number(userId)].checked)
          .some((checked) => checked) && (
          <Box pl={40} pb={24}>
            <Button size="md" variant="subtle" leftIcon={<IconPlus stroke="1.5" />} onClick={addPeopleToOrganization}>
              Pridať ľudí
            </Button>
          </Box>
        )}

      {Object.keys(form.values.peopleInDepartments).map((departmentId) => {
        const people = form.values.peopleInDepartments[departmentId];
        const departmentName = people[0].departmentName ?? '-';

        return (
          <Box key={departmentId}>
            <FormInputGroup groupTitle={`Ľudia na stredisku: ${departmentName}`}>
              {isEmpty(people) && (
                <Group pt={26} spacing={8}>
                  <P1Regular color="gray.6">Stredisko zatiaľ nemá priradených žiadnych ľudí.</P1Regular>
                </Group>
              )}

              <SimpleGrid cols={2} spacing={40} verticalSpacing={24}>
                {people.map(({ userId, permissions, templateName }) => {
                  return (
                    <PersonWithPermissions
                      key={userId}
                      userId={Number(userId)}
                      permissions={permissions}
                      templateSlug={templateName}
                      inForm="organization"
                      updatePermissionsForPerson={noop}
                      onUserDelete={noop}
                      hideControls={true}
                      // the value is here so that the component works
                      value={form.values.people}
                    />
                  );
                })}
              </SimpleGrid>
            </FormInputGroup>
          </Box>
        );
      })}
    </FormWrapper>
  );
}
