import { useConfirm } from 'components/modals/message/MessageProvider';
import { Blocker, useBlocker } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { UseFormReturnType } from '@mantine/form';
import { FEATURE_TOGGLE_FORM_CONFIRMATION_BEFORE_LEAVE } from 'env';

/**
 * Handles the scenario where the user tries to navigate away from a form with unsaved changes,
 * prompting them with a confirmation dialog.
 *
 * @param {UseFormReturnType<any>} form - The form instance with potential unsaved changes.
 * @return {Blocker} The blocker instance that manages the navigation blocking behavior.
 */
export function useDirtyFormHandler<T>(form: UseFormReturnType<T>): Blocker {
  const { confirm } = useConfirm();
  const blocker = useBlocker(() => form.isDirty?.() || false);

  const confirmBeforeFormLeft = useCallback(() => {
    confirm({
      title: 'Potvrdenie pokračovania',
      content: 'Vo formulári sú neuložené zmeny. Naozaj chcete pokračovať?',
      onConfirm: () => {
        blocker.proceed?.();
      },
    });
  }, [confirm, blocker]);

  useEffect(() => {
    if (FEATURE_TOGGLE_FORM_CONFIRMATION_BEFORE_LEAVE && blocker.state === 'blocked') {
      confirmBeforeFormLeft();
    }
  }, [blocker.state, confirmBeforeFormLeft]);

  return blocker;
}
