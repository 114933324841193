import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserGetTableProfilesPathParameters } from 'api/actions/user-get-table-profiles/user-get-table-profiles-path-parameters';
import { UserGetTableProfilesResponse } from 'api/actions/user-get-table-profiles/user-get-table-profiles-response';

type UserGetTableProfilesQueryParameters = Record<string, any>;

/**
 * The path of the UserGetTableProfiles action.
 */
export const userGetTableProfilesActionPath = new SweetPath<keyof UserGetTableProfilesPathParameters>(
  '/api/user/table-profiles/{tableId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized UserGetTableProfiles actions.
 */
export default function createUserGetTableProfilesAction(axios: AxiosInstance) {
  return function userGetTableProfilesAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: UserGetTableProfilesPathParameters;
    query?: UserGetTableProfilesQueryParameters;
  }) {
    const path = userGetTableProfilesActionPath.insert(parameters);

    return axios.get<UserGetTableProfilesResponse, UserGetTableProfilesResponse>(path, { params: query, headers });
  };
}
