import { useForm } from '@mantine/form';
import { FormProps } from 'components/forms/FormProps';
import useLoadingAction from 'hooks/use-loading-action';
import { isEmpty, noop } from 'lodash';
import FormWrapper from 'components/forms/FormWrapper';
import { Button, Divider, Flex, Group, Image, SimpleGrid, Stack, TextInput } from '@mantine/core';
import { IconCheck, IconPlus, IconTrashX } from '@tabler/icons-react';
import FormInputGroup from 'components/forms/FormInputGroup';
import UploadFile from 'components/files/UploadFile';
import { nanoid } from 'nanoid';
import { useCallback, useState } from 'react';
import DeviceTypeLabel from 'components/device/DeviceTypeLabel';
import { usePickDeviceType } from 'components/modals/pick-device-type/PickDeviceTypeProvider';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import H1Medium from 'components/typography/H1Medium';
import { DateInput } from 'components/inputs/DateInput';
import { useConfirm } from 'components/modals/message/MessageProvider';
import { useDirtyFormHandler } from 'hooks/use-dirty-form-handler';

/**
 * The data collected from the form.
 */
export interface MyCertificatesFormData {
  certificates: {
    type: string;
    number: string;
    renewalDate: string;
    certificate: string;
    stamp: string;
  }[];
}

/**
 * Form for editing user's own certificates.
 */
export default function MyCertificatesForm({
  initialValues = {
    certificates: [],
  },
  onSubmit = noop,
}: FormProps<MyCertificatesFormData>) {
  const { pickDeviceType } = usePickDeviceType();
  const { confirm } = useConfirm();
  const [{ loading }, submit] = useLoadingAction(onSubmit);

  const [extendedInitialValues] = useState(() => ({
    ...initialValues,
    certificates: initialValues.certificates.map((certificate) => ({ ...certificate, uuid: nanoid() })),
  }));

  const form = useForm({
    initialValues: extendedInitialValues,
    validate: {
      certificates: {
        number: createValidator([required]),
        renewalDate: (date) => (date ? null : 'Pole je povinné'),
        certificate: createValidator([required]),
        stamp: createValidator([required]),
      },
    },
  });

  useDirtyFormHandler(form);

  /**
   * Adds a new qualification to the form.
   */
  const addCertificate = useCallback(() => {
    pickDeviceType({
      title: 'Pridať osvedčenie revízneho technika',
      addButtonLabel: 'Pridať osvedčenie',
      onPick: ({ slug }) =>
        form.insertListItem('certificates', {
          uuid: nanoid(),
          type: slug,
          number: '',
          renewalDate: '',
          certificate: '',
          stamp: '',
        }),
    });
  }, [pickDeviceType, form]);

  /**
   * Asks the user to confirm the removal of the certificate.
   */
  const removeCertificate = useCallback(
    (index: number) => {
      confirm({
        title: 'Zmazať osvedčenie',
        content: 'Naozaj chcete zmazať osvedčenie?',
        onConfirm: () => form.removeListItem('certificates', index),
      });
    },
    [confirm, form]
  );

  return (
    <FormWrapper
      pt={24}
      onSubmit={form.onSubmit(submit)}
      loading={loading}
      primaryButtonText="Uložiť"
      primaryIcon={<IconCheck stroke="1.5" height={24} width={24} />}
      skipSecondaryButtonConfirm={!form.isDirty()}
    >
      {form.values.certificates.map((certificate, i) => (
        <Stack key={certificate.uuid} pb={0} spacing={0}>
          <Group px={40} position="apart">
            <DeviceTypeLabel size="md" deviceType={certificate.type} />
            <Button
              leftIcon={<IconTrashX stroke="1.5" />}
              variant="danger-secondary"
              onClick={() => removeCertificate(i)}
            >
              Zmazať osvedčenie
            </Button>
          </Group>
          <FormInputGroup groupTitle="">
            <SimpleGrid cols={2} spacing={40}>
              <TextInput
                size="lg"
                label="Číslo osvedčenia"
                placeholder="Číslo osvedčenia"
                name={`amperia-certificates.${i}.number`}
                {...form.getInputProps(`certificates.${i}.number`)}
              />
              <DateInput
                size="lg"
                label="Aktualizačná príprava"
                placeholder="Aktualizačná príprava"
                {...form.getInputProps(`certificates.${i}.renewalDate`)}
              />
            </SimpleGrid>

            <SimpleGrid cols={2} spacing={40}>
              <UploadFile
                label="Pečiatka"
                description="Pridajte pečiatku revízneho technika"
                showSlider={false}
                {...form.getInputProps(`certificates.${i}.stamp`)}
              />
              <UploadFile
                label="Osvedčenie"
                description="Pridajte osvedčenie revízneho technika"
                showSlider={false}
                {...form.getInputProps(`certificates.${i}.certificate`)}
              />
            </SimpleGrid>
          </FormInputGroup>

          <Divider color="gray.1" size={1} mt={16} mb={24} mx={40} />
        </Stack>
      ))}

      {isEmpty(form.values.certificates) && (
        <Flex mx={40} mt={16} bg="gray.0" py={40} sx={{ borderRadius: '4px' }} justify="center" align="center">
          <Stack spacing={24} maw={384} justify="center" align="center">
            <Image src="/img/no-certificates.svg" width={384} h="auto" />
            <Stack spacing={8} justify="center" align="center" sx={{ textAlign: 'center' }}>
              <H1Medium color="gray.7">Zatial nemáte pridané žiadne osvedčenia</H1Medium>
              <Button variant="subtle" size="md" leftIcon={<IconPlus stroke="1.5" />} onClick={addCertificate}>
                Pridať osvedčenie
              </Button>
            </Stack>
          </Stack>
        </Flex>
      )}

      {!isEmpty(form.values.certificates) && (
        <Stack align="flex-start" pl={40} pb={24}>
          <Button variant="subtle" size="md" leftIcon={<IconPlus stroke="1.5" />} onClick={addCertificate}>
            Pridať osvedčenie
          </Button>
        </Stack>
      )}
    </FormWrapper>
  );
}
